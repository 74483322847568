<template>
  <div class="company-box">
    <div class="left">
      <div class="logo">
        <span>{{ currentUserInfo.tenantName ? currentUserInfo.tenantName.substr(0,2) : '' }}</span>
      </div>
      <div class="name-box">
        <div class="name-status">
          <span class="name">{{ currentUserInfo.tenantName }}</span>
          <div :class="['status-box', statusObj.statusCode === '1' ? 'grey' : statusObj.statusCode === '2' ? 'orange' : 'green']">
            <img :src="statusObj.img" />
            <span>{{ statusObj.status }}</span>
          </div>
        </div>
        <div class="welcome">欢迎来到小紫平台，已加入小紫平台 {{ currentUserInfo.employeeMsg.joinDays || 0 }} 天</div>
      </div>
    </div>
    <div class="right">
      <div @click="showVisible" class="button button-1">
        <img :src="require('@/assets/imgs/workbench/ic_switch.svg')" />
        切换
      </div>
      <!-- <div class="button button-2">
        <img :src="require('@/assets/imgs/workbench/icon_edit.svg')" />
        企业管理
      </div> -->
    </div>
    <el-dialog title="切换" :visible.sync="visible" width="500px">
      <company-list :companyLists='companyLists' @refresh="refresh" />
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import CompanyList from '@/components/common/companyList/index.vue';
import { createTenant } from '@/api/company';
import switchCompany from '@/utils/switchCompany';
export default {
  components: {
    CompanyList,
  },
  props: {
    currentUserInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    'currentUserInfo.tenantId' () {
      this.statusObj = this.statusList.find(item => this.currentUserInfo.authenticationStatus === item.statusCode);
    },
    'currentUserInfo.authenticationStatus' () {
      this.statusObj = this.statusList.find(item => this.currentUserInfo.authenticationStatus === item.statusCode);
    },
  },
  data () {
    return {
      statusObj: {},
      visible: false,
      companyLists: [],
      statusList: [
        {
          status: '未认证',
          statusCode: '1',
          img: require('@/assets/ic_weirenzheng.svg'),
        },
        {
          status: '认证中',
          statusCode: '2',
          img: require('@/assets/ic_renzhengzhong.svg'),
        },
        {
          status: '已认证',
          statusCode: '3',
          img: require('@/assets/ic_yirenzheng.svg'),
        },
      ],
    };
  },
  mounted () {
    this.statusObj = this.statusList.find(item => this.currentUserInfo.authenticationStatus === item.statusCode);
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    toCreate () {
      this.visible = false;
      this.dialogVisible = true;
    },
    async save (type) {
      if (!this.company) return;

      const { employeeMsg } = JSON.parse(this.$local.get('userInfo'));

      const list = await createTenant({
        id: employeeMsg.id,
        tenantNames: [this.company],
      });

      await switchCompany(list.body[0].tenantId);

      this.updateUserInfo(JSON.parse(this.$local.get('userInfo')));

      this.company = '';
      this.dialogVisible = false;
      this.$message.success('企业创建成功');

      this.$tabs.items = [];
      this.$tabs.close({
        to: '/workbench',
        refresh: true,
      });
      window.location.reload();

      if (type === 2) {
        setTimeout(() => {
          this.$router.push({
            path: '/workbench/identification',
          });
        }, 2000);
      }
    },
    showVisible () {
      this.companyLists = JSON.parse(this.$local.get('ownerList')) || [];
      this.visible = true;
    },
    refresh () {
      this.visible = false;
      this.$tabs.items = [];
      this.$tabs.close({
        to: '/workbench',
        refresh: true,
      });
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.company-box {
  font-size: 14px;
  color: #1F2733;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  .left {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .logo {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      color: #237FFA;
      background-color: #E2F3FE;
      font-size: 16px;
      margin-right: 10px;
    }
    .name-box {
      // height: 48px;
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      .name-status {
        display: inline-flex;
        align-items: center;
        .name {
          font-size: 16px;
          font-weight: bold;
          padding-right: 15px;
        }
        .status-box {
          font-size: 12px;
          font-weight: 400;
          border-radius: 4px;
          padding: 2px 6px;
          display: inline-flex;
          align-items: center;
          > img {
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
        }
        .grey {
          background: #F2F4F7;
          color: #1F2733;
        }
        .orange {
          background: #FEFAE0;
          color: #FAB007;
        }
        .green {
          background: #E5FEEB;
          color: #38D677;
        }
      }
      .welcome {
        color: #929AA6;
      }
    }
  }
  .right {
    display: inline-flex;
    align-items: center;
    .button {
      padding: 6px 13px;
      border-radius: 4px;
      cursor: pointer;
      > img {
        width: 14px;
        height: 14px;
      }
    }
    .button-1 {
      color: #237FFA;
      background: #E2F3FE;
      margin-right: 10px;
    }
    .button-2 {
      color: #ffffff;
      background: #237FFA;
    }
  }
}
</style>
